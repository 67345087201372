import { FlywheelAnalytics } from "@flywheelapp/flywheel.js";

const METHODS = [
  "trackSubmit",
  "trackClick",
  "trackLink",
  "trackForm",
  "pageview",
  "identify",
  "reset",
  "group",
  "track",
  "ready",
  "alias",
  "debug",
  "page",
  "once",
  "off",
  "on",
  "addSourceMiddleware",
  "addIntegrationMiddleware",
  "setAnonymousId",
  "addDestinationMiddleware",
] as const;

type MethodsRecord<T> = Record<(typeof METHODS)[number], T>;
type MethodStubs = MethodsRecord<() => void>;
type PreLibInitBuffer = MethodsRecord<unknown[]>;

export type FlywheelSnippet = {
  writeKey: string;
  apiHost: string;
  snippetVersion: string;
  preLibInitBuffer: PreLibInitBuffer;
  runInIframe: "true" | "false";
} & MethodStubs;

declare global {
  interface Window {
    flywheel: FlywheelAnalytics;
  }
}

const { writeKey, apiHost, runInIframe } =
  window.flywheel as unknown as FlywheelSnippet;
window.flywheel = FlywheelAnalytics.load({ writeKey, apiHost, runInIframe });
